<script setup>
import {useTranslation,} from "@/assets/js/src/util/composables/useTranslation"

defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    applyLabel: {
        type: String,
        default: '',
    },
    showButtons: {
        type: Boolean,
        default: true,
    },
    compact: {
        type: Boolean,
        default: false,
    },
})
defineEmits([ 'cancel', 'apply', ])

const { t,} = useTranslation()
</script>

<template>
    <div
        class="btn-group-cancel-apply hidden-print-only"
    >
        <div
            class="d-flex align-center"
        >
            <slot></slot>
        </div>
        <transition name="slidey">
            <div
                v-if="showButtons"
                class="btn-group-cancel-apply__group"
            >
                <v-btn
                    :aria-label="t('async.common.button.cancel')"
                    :title="t('async.common.button.cancel')"
                    class="btn-group-cancel-apply__cancel-btn"
                    variant="text"
                    @click.stop="$emit('cancel')"
                    @keyup.stop.prevent.enter="$emit('cancel')"
                    @keyup.stop.prevent.space="$emit('cancel')"
                >
                    <span :class="[compact ? 'd-none' : 'hidden-xs']">
                        {{ t('async.common.button.cancel') }}
                    </span><span :class="{'hidden-sm-and-up':!compact}">
                        <v-icon>bs:$vuetify.icons.mdiClose</v-icon>
                    </span>
                </v-btn>
                <v-btn
                    :aria-label="applyLabel || t('async.common.button.apply')"
                    :title="applyLabel || t('async.common.button.apply')"
                    class="btn-group-cancel-apply__apply-btn"
                    color="primary"
                    :loading="loading"
                    :disabled="disabled"
                    @click.stop="$emit('apply', $event)"
                    @keyup.stop.prevent.enter="$emit('apply', $event)"
                    @keyup.stop.prevent.space="$emit('apply', $event)"
                >
                    <span :class="[compact ? 'd-none' : 'hidden-xs']">
                        {{ applyLabel || t('async.common.button.apply') }}
                    </span><span :class="{'hidden-sm-and-up':!compact}">
                        <v-icon>bs:$vuetify.icons.mdiCheck</v-icon>
                    </span>
                </v-btn>
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
    .bs-app .btn-group-cancel-apply {
        display: flex;
        justify-content: space-between;
        min-height: 52px;

        &__cancel-btn {
            span {
                color: map-deep-get($bs-color, black);

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                }

                font-weight: 500;
                font-size: 14px;
            }
        }

        &__apply-btn {
            span {
                color: white;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .slidey {
            &-enter-from, &-leave-to {
                transform: translateY(100%);
            }

            &-enter-active, &-leave-active {
                transition: .3s cubic-bezier(.25, .8, .5, 1);
            }

            &-enter-to, &-leave-from {
                transform: translateY(0);
            }
        }
    }
</style>